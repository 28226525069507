import React, { useContext, useEffect, useState } from "react";
import { PageTitle } from "../components/PageTitle";
import { Client } from "./../services/primiscService";
import Prismic from "@prismicio/client";
import { RichText, Date } from "prismic-reactjs";
import { Spinner } from "../components/Spinner";
import { StoreContext } from "../services/store.context";

const News = () => {
  const [news, setNews] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const { updateNews } = useContext(StoreContext);

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      const response = (await Client.query(
        Prismic.Predicates.at("document.type", "news"),
        { orderings : '[my.news.start desc]' }
      )) as any;
      if (response) {
        updateNews(response.results)
        setNews(response.results);
      }
      setLoading(false);
    };
    fetchData();
  }, [updateNews]);

  return (
    <div>
      <PageTitle title="Novedades!" />
      <div className="">
        <div className="text-gray-600 mb-4">
          Conozca nuestras nuevas funcionalidades
        </div>
        {loading ? (
          <Spinner />
        ) : (
          news.map((newsItem) => (
            <div
              key={newsItem.uid}
              className="section-border p-4 mb-4 w-full"
            >
              <div>
                <h1 className="text-xl tracking-wide font-semibold uppercase">
                  {RichText.asText(newsItem.data['title-es'])}
                </h1>
                <h2 className="mb-2 text-gray-600">
                  {Date(newsItem.data.start).toLocaleDateString()}
                </h2>
                <p className="whitespace-pre-wrap break-words text-gray-600 my-4">
                  {RichText.asText(newsItem.data['description-es'])}
                </p>
              </div>

              {newsItem.data.image.url && (
                <img
                  alt="cover"
                  src={newsItem.data.image.url}
                  className="w-full shadow-md mt-4 xl:mt-0 xl:w-2/3 2xl:w-1/2"
                />
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default News;
