import { Subject } from "rxjs";
import { Client } from "@stomp/stompjs";

export class Socket {
  private static instance: Socket;
  private connected = false;
  private socketSubject = new Subject();
  private stompClient: Client;

  private constructor() {
    const token = localStorage.getItem("tokenServiteOnline");
    this.stompClient = new Client({
      brokerURL: `wss://api.serviteonline.com/ws/websocket?access_token=${token}`,
      // If disconnected, it will retry after 200ms
      reconnectDelay: 200,
      onConnect: (frame) => {
        console.log("socket connected");
        this.stompClient.subscribe("/user/topic/public", (event: any) => {
          this.socketSubject.next(event.body);
        });
      },
      onStompError: () => {
        this.stompClient.activate();
      },
    });
    
    this.stompClient.activate();
  }

  public static getInstance(): Socket {
    if (!Socket.instance) {
      Socket.instance = new Socket();
    }
    return Socket.instance;
  }

  public onOrder() {
    return this.socketSubject.asObservable();
  }
}
