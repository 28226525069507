import React from "react";
import DailyReport from "../components/DailyReport";
import MonthlyReport from "../components/MonthlyReport";
import MonthlyReportCustomers from "../components/MonthlyReportCustomers";
import YearlyReportCustomers from "../components/YearlyReportCustomers";
import PerpetualReportCustomers from "../components/PerpetualReportCustomers";
import { PageTitle } from "../components/PageTitle";
import { Tab } from "@headlessui/react";

const tabs = ["DIARIO", "MENSUAL"];
const tabsCustomers = ["MENSUAL", "ANUAL", "HISTÓRICO"];

const Reports: React.FC = () => {
  const classNames = (...classes: string[]) =>
    classes.filter(Boolean).join(" ");

  return (
    <>
      <PageTitle title="Reportes" />
      <div className="xl:flex w-full xl:gap-4">
        <div className="flex-grow section-border p-4 ">
          <div className="text-lg mb-2 border-b border-gray-300 pb-1 font-semibold uppercase">
            Ventas
          </div>
          <Tab.Group>
            <Tab.List className="w-full lg:w-72 font-semibold grid grid-cols-2 gap-2 border-b border-gray-300">
              {tabs.map((tab) => (
                <Tab
                  key={tab}
                  className={({ selected }) =>
                    classNames(
                      "w-full py-2 text-sm leading-5 font-medium text-gray-600",
                      "focus:outline-none",
                      selected
                        ? "text-primary-300 border-b-2 border-primary-200"
                        : " hover:opacity-50"
                    )
                  }
                >
                  {tab}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="my-4 relative">
              <Tab.Panel>
                <DailyReport />
              </Tab.Panel>
              <Tab.Panel>
                <MonthlyReport />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
        <div className="mt-4 xl:mt-0 xl:w-80 flex-grow-0 section-border p-4">
          <div className="text-lg mb-2 border-b border-gray-300 pb-1 font-semibold uppercase">
            Ventas por cliente
          </div>
          <Tab.Group>
            <Tab.List className="w-full grid grid-cols-3 gap-2 border-b border-gray-300">
              {tabsCustomers.map((tab) => (
                <Tab
                  key={tab}
                  className={({ selected }) =>
                    classNames(
                      "w-full py-2 text-sm leading-5 font-medium text-gray-600",
                      "focus:outline-none",
                      selected
                        ? "text-primary-300 border-b-2 border-primary-200"
                        : " hover:opacity-50"
                    )
                  }
                >
                  {tab}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="my-4 relative">
              <Tab.Panel>
                <MonthlyReportCustomers />
              </Tab.Panel>
              <Tab.Panel>
                <YearlyReportCustomers />
              </Tab.Panel>
              <Tab.Panel>
                <PerpetualReportCustomers />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </>
  );
};

export default Reports;
