import React, { useEffect, useState } from "react";
import apiService from "../services/api";
import { Spinner } from "./Spinner";

type User = {
  phoneNumber: string;
  total: number;
  lastNameUser: string;
};

const PerpetualReportCustomers: React.FC = () => {
  const [loading, setLoading] = useState("idle");
  const [reportData, setReportData] = useState([] as User[]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading("loading");
      try {
        const response = await apiService.getCall(
          `stats/user/top/perpetual/COUNT/20`
        );
        setReportData(response);
        setLoading("loaded");
      } catch (error) {
        setLoading("error");
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {loading === "error" && (
        <div className="text-white p-4 rounded-lg shadow-md bg-red-500">
          Error al cargar información, revise la fecha seleccionada o intente
          volver a cargar la página.
        </div>
      )}
      {loading === "loading" && <Spinner />}
      {loading === "loaded" && (
        <div className="mt-2">
          {reportData.length > 0 ? (
            reportData.map((customer: User, index) => (
              <div className="flex justify-between mb-1" key={index}>
                <div>{customer.phoneNumber}</div>
                <div className="font-semibold">{customer.total}</div>
              </div>
            ))
          ) : (
            <div className="pt-2 text-sm text-gray-600">
              No han habido ventas este mes.
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PerpetualReportCustomers;
