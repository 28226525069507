import React, { useEffect, useState } from "react";
import apiService from "../services/api";
import { Spinner } from "./Spinner";
import { format, subDays, addDays } from "date-fns";
import DatePicker from "react-datepicker";

type Item = {
  itemName: string;
  count: number;
};
type SubItem = {
  itemName: string;
  count: number;
  subItemName: string;
};
export type ReportType = {
  totalAmount: number;
  totalCount: number;
  items: Item[];
  subItems: SubItem[];
  menuViewCount: number;
  rejectOrderCount: number;
  averagePricePerOrder: number;
  totalDeliveryCost: number;
};
const DailyReport: React.FC = () => {
  const [loading, setLoading] = useState("idle");
  const [reportData, setReportData] = useState({} as ReportType);
  const [dateSelected, setDateSelected] = useState(new Date());

  useEffect(() => {
    const fetchData = async () => {
      setLoading("loading");
      try {
        const formattedDate = format(new Date(dateSelected), "yyyy-MM-dd");
        const response = await apiService.getCall(
          `stats/store/daily/${formattedDate}`
        );
        setReportData({
          ...response,
          items: response.items.filter((item: Item) => item.count > 0),
          subItems: response.subItems.filter(
            (subItem: SubItem) => subItem.count > 0
          ),
        });
        setLoading("loaded");
      } catch (error) {
        setLoading("error");
      }
    };
    fetchData();
  }, [dateSelected]);

  return (
    <>
      <div className="flex items-center mb-3 space-x-4">
        <label className="flex items-center w-auto">
          <DatePicker
            className="input form-input w-56 z-2"
            selected={dateSelected}
            maxDate={new Date()}
            onChange={(date) => setDateSelected(date as Date)}
            dateFormat="yyyy-MM-dd"
          />
          <svg
            className="h-6 w-6 text-gray-900"
            style={{ transform: "translateX(-36px)" }}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
        </label>
        <div className="flex rounded-lg shadow bg-white mr-4 cursor-pointer">
          <button
            className="flex items-center p-2 border-r border-gray-500 hover:opacity-50"
            onClick={() => setDateSelected(subDays(dateSelected, 1))}
          >
            <svg
              className="fill-current h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 26 26"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline fill="none" points="15 18 9 12 15 6"></polyline>
            </svg>
          </button>
          <button
            className="flex items-center p-2 hover:opacity-50"
            onClick={() => setDateSelected(addDays(dateSelected, 1))}
          >
            <svg
              className="fill-current h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline fill="none" points="9 18 15 12 9 6"></polyline>
            </svg>
          </button>
        </div>
      </div>
      {loading === "error" && (
        <div className="text-white p-4 rounded-lg shadow-md bg-red-500 w-max">
          Error al cargar información, revise la fecha seleccionada o intente
          volver a cargar la página.
        </div>
      )}
      {loading === "loading" && <Spinner />}
      {loading === "loaded" && (
        <div>
          <h2 className="text-xl my-2">Estadísticas generales</h2>
          <div
            className="grid gap-4 item-center mb-2"
            style={{
              gridTemplateColumns: "repeat(auto-fill, minmax(192px, 1fr))",
            }}
          >
            <div className="px-3 py-2 rounded-lg shadow-md border border-gray-300 bg-white">
              <div className="text-gray-600 text-xs">Total vendido</div>
              <div className="pr-1">
                {"$" + reportData.totalAmount.toFixed(2)}
              </div>
            </div>
            <div className="px-3 py-2 rounded-lg shadow-md border border-gray-300 bg-white">
              <div className="text-gray-600 text-xs">Total de órdenes</div>
              <div className="pr-1">{reportData.totalCount}</div>
            </div>
            <div className="px-3 py-2 rounded-lg shadow-md border border-gray-300 bg-white">
              <div className="text-gray-600 text-xs">
                Total de visitas al menú
              </div>
              <div className="pr-1">
                {reportData.menuViewCount ? reportData.menuViewCount : "-"}
              </div>
            </div>
            <div className="px-3 py-2 rounded-lg shadow-md border border-gray-300 bg-white">
              <div className="text-gray-600 text-xs">
                Total de órdenes rechazadas
              </div>
              <div className="pr-1">
                {reportData.rejectOrderCount
                  ? reportData.rejectOrderCount
                  : "-"}
              </div>
            </div>
            <div className="px-3 py-2 rounded-lg shadow-md border border-gray-300 bg-white">
              <div className="text-gray-600 text-xs">
                Monto promedio de órdenes
              </div>
              <div className="pr-1">
                {reportData.averagePricePerOrder
                  ? "$" + reportData.averagePricePerOrder.toFixed(2)
                  : "-"}
              </div>
            </div>
            <div className="px-3 py-2 rounded-lg shadow-md border border-gray-300 bg-white">
              <div className="text-gray-600 text-xs">Total de delivery</div>
              <div className="pr-1">
                {"$" + reportData.totalDeliveryCost.toFixed(2)}
              </div>
            </div>
          </div>
          <h2 className="text-xl mt-6 mb-4">Productos más vendidos</h2>
          <div className="mt-2 rounded-lg shadow-md border border-gray-300 bg-white">
            {reportData.items.length > 0 ? (
              <div className="pb-2">
                <div className="p-2 mt-1 flex justify-between border-b border-gray-200 text-gray-800 font-semibold">
                  <div>Producto</div>
                  <div>Cantidad vendida</div>
                </div>
                {reportData.items.map((item, index) => (
                  <div
                    key={index}
                    className="px-2 py-1 flex justify-between text-gray-700 border-t first-of-type:border-none border-gray-100"
                  >
                    <div className="break-all pr-2">{item.itemName}</div>
                    <div className="pr-2">{item.count}</div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="p-4 text-sm text-gray-600">
                No existe registro de productos vendidos para la fecha
                seleccionada.
              </div>
            )}
          </div>
          <h2 className="text-xl mt-6 mb-4">Sub Items más vendidos</h2>
          <div className="mt-2 rounded-lg shadow-md border border-gray-300 bg-white">
            {reportData.subItems.length > 0 ? (
              <div className="pb-2">
                <div className="p-2 mt-1 flex justify-between border-b border-gray-200 text-gray-800 font-semibold">
                  <div>Producto</div>
                  <div>Cantidad vendida</div>
                </div>
                {reportData.subItems.map((subItem) => (
                  <div className="px-2 py-1 flex justify-between text-gray-700 border-t first-of-type:border-none border-gray-100">
                    <div className="break-all pr-2">
                      {subItem.subItemName}&nbsp;
                      <span className="text-xs text-gray-600">
                        {subItem.itemName}
                      </span>
                    </div>
                    <div className="pr-2">{subItem.count}</div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="p-4 text-sm text-gray-600">
                No existe registro de subitems vendidos para la fecha
                seleccionada.
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default DailyReport;
