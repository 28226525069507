import React from 'react';
import { SubItem } from '../types';
import { Tooltip } from './Tooltip';

export interface SubItemProps {
  subItem: SubItem;
  removeSubItem: (index: number) => void;
  editSubItem: (item: SubItem) => void;
  changeVisibility: (id: number, value: boolean) => void;
}

export const SubItemElement = ({
  subItem,
  removeSubItem,
  editSubItem,
  changeVisibility,
}: SubItemProps) => {
  return (
    <div
      key={subItem.id}
      className="w-full flex p-2 border bg-white rounded-lg justify-between my-1 border-gray-300"
    >
      <div className="flex items-center">
        <svg
          className="mr-1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
        </svg>
        <div className="pl-2">{subItem.name}</div>
      </div>
      <div className="self-end">
        <div className="flex items-center">
          <div className="mr-3">${subItem.price}</div>
          {subItem.hidden ? (
            <Tooltip text="Habilitar Producto">
              <svg
                onClick={() => changeVisibility(subItem.id, false)}
                className="cursor-pointer hover:opacity-75 mr-2 text-red-600"
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                />
              </svg>
            </Tooltip>
          ) : (
            <Tooltip text="Deshabilitar Producto">
              <svg
                onClick={() => changeVisibility(subItem.id, true)}
                className="cursor-pointer hover:opacity-75 mr-2"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                />
              </svg>
            </Tooltip>
          )}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="cursor-pointer hover:opacity-75 mx-1"
            width="24"
            height="24"
            onClick={() => editSubItem(subItem)}
          >
            <path d="M6.3 12.3l10-10a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1 0 1.4l-10 10a1 1 0 0 1-.7.3H7a1 1 0 0 1-1-1v-4a1 1 0 0 1 .3-.7zM8 16h2.59l9-9L17 4.41l-9 9V16zm10-2a1 1 0 0 1 2 0v6a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2h6a1 1 0 0 1 0 2H4v14h14v-6z" />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="cursor-pointer hover:opacity-75 mr-2 fill-current"
            width="24"
            height="24"
            onClick={() => removeSubItem(subItem.id)}
          >
            <path d="M8 6V4c0-1.1.9-2 2-2h4a2 2 0 0 1 2 2v2h5a1 1 0 0 1 0 2h-1v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V8H3a1 1 0 1 1 0-2h5zM6 8v12h12V8H6zm8-2V4h-4v2h4zm-4 4a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0v-6a1 1 0 0 1 1-1zm4 0a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0v-6a1 1 0 0 1 1-1z" />
          </svg>
        </div>
      </div>
    </div>
  );
};
