import React, { useState } from "react";
import {
  DragDropContext,
  Droppable,
  DropResult,
  Draggable,
  DroppableProvided,
} from "react-beautiful-dnd";
import { SubItemElement } from "./SubItemElement";
import { PopUp, PopUphandler } from "./PopUp";
import apiService from "../services/api";
import { Button } from "./Button";
import { SubCategory, SubItem } from "../types";

type ProductProps = {
  subCategory: SubCategory;
  reloadItem: () => void;
  changeProduct: (subCategory: SubCategory) => void;
};

const minOptions = Array.from(Array(30).keys());
const maxOptions = [
  "sin limite",
  ...Array.from(Array(30).keys()).splice(1, 30),
];

export const SubCategoryDetails: React.FC<ProductProps> = ({
  subCategory,
  reloadItem,
  changeProduct,
}) => {
  const [newSubItemForm, setNewSubItemForm] = useState({
    name: "",
    price: "",
    hidden: false,
  });
  const [editSubItemForm, setEditSubItemForm] = useState({} as SubItem);
  // const [subCategorySelected, setSubcategorySelected] = useState(subCategory);
  const [confirmationPopUp, setConfirmationPopUp] = useState(
    {} as PopUphandler
  );
  const saveChangesSubCategory = async () => {
    await apiService.putCall(subCategory, `menu/subitem/category/update`);
    reloadItem();
  };
  const [status, setStatus] = useState("idle");

  const reorder = (list: SubItem[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEndSubItem = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const subCategoriesOrdered = reorder(
      subCategory.subItems,
      result.source.index,
      result.destination.index
    );

    const subItemsUpdated = subCategoriesOrdered.map((subItem: any, index) => ({
      ...subItem,
      position: index,
    }));

    apiService.patchCall(
      subItemsUpdated,
      `menu/subitem/update-order/${subCategory.id}`
    );

    changeProduct({
      ...subCategory,
      subItems: subItemsUpdated,
    });
  };

  const removeSubItem = (subItemId: number) => {
    setConfirmationPopUp({
      handler: async (_event, confirmation: boolean) => {
        if (confirmation) {
          const response = await apiService.deleteCall(
            `menu/subitem/remove/${subItemId}`
          );
          if (!response.error) {
            reloadItem();
          }
        }
        setConfirmationPopUp({} as PopUphandler);
      },
      message: "Estas seguro que desea eliminar esta sub item?",
    });
  };

  const changeVisibilitySubItem = async (id: number, value: boolean) => {
    if (status === "idle") {
      await apiService.putCall({}, `menu/subitem/${id}/hide/${value}`);
      reloadItem();
    }
  };

  const addNewSubItem = async () => {
    if (status === "idle") {
      setStatus("updating");
      const response = await apiService.postCall(
        newSubItemForm,
        `menu/subitem/add/${subCategory.id}`
      );
      if (!response.error) {
        reloadItem();
      }
      setNewSubItemForm({
        name: "",
        price: "",
        hidden: false,
      });
      setStatus("idle");
    }
  };

  const editSubItem = (subItem: SubItem) => {
    setEditSubItemForm(subItem);
  };

  const handleSubItemEdition = async (
    _event: React.MouseEvent<HTMLElement>,
    confirmation: boolean
  ) => {
    if (confirmation) {
      const response = await apiService.putCall(
        editSubItemForm,
        "menu/subitem/update"
      );
      if (!response.error) {
        reloadItem();
      }
    }
    setEditSubItemForm({} as SubItem);
  };

  return (
    <>
      {editSubItemForm.name && (
        <PopUp
          closePopUp={handleSubItemEdition}
          message={`Editar Sub Item de la categoría ${subCategory.name}`}
        >
          <div className="flex flex-wrap mt-4 mb-2">
            <div className="w-full mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Nombre
              </label>
              <input
                className="input"
                type="text"
                max="200"
                onChange={(event) =>
                  setEditSubItemForm({
                    ...editSubItemForm,
                    name: event.target.value,
                  })
                }
                value={editSubItemForm.name}
              />
            </div>
            <div className="w-full mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Precio ($)
              </label>
              <input
                className="input"
                type="number"
                value={editSubItemForm.price}
                onChange={(event) =>
                  setEditSubItemForm({
                    ...editSubItemForm,
                    price: Number(event.target.value),
                  })
                }
              />
            </div>
          </div>
        </PopUp>
      )}
      {confirmationPopUp.message && (
        <PopUp
          closePopUp={confirmationPopUp.handler}
          message={confirmationPopUp.message}
        />
      )}
      <div className="flex-grow">
        <div className="flex flex-wrap flex-col">
          <div className="p-4 flex-grow bg-white rounded-lg">
            <div className="text-xl">Información Sub Categoría</div>
            <p className="text-gray-600 mb-3 text-sm">
              Opciones de selección de cada item de la Sub Categoría
            </p>
            <div className="mb-2 flex items-center">
              <input
                value={subCategory.name}
                placeholder="Nombre"
                onChange={(event) => {
                  changeProduct({
                    ...subCategory,
                    name: event.target.value,
                  });
                }}
                type="text"
                className="input w-full"
              />
            </div>
            <div className="grid grid-cols-2 gap-2 mt-4">
              <div>
                <div className="block text-sm font-medium text-gray-700 pr-2 w-40 flex-grow-0">
                  Mínima Permitida
                </div>
                <div>
                  <select
                    value={subCategory.minSelection}
                    onChange={(event) => {
                      changeProduct({
                        ...subCategory,
                        minSelection: Number(event.target.value),
                      });
                    }}
                    className="input w-full"
                  >
                    {minOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div>
                <div className="block text-sm font-medium text-gray-700 pr-2 w-40 flex-grow-0">
                  Máxima Permitida
                </div>
                <div>
                  <select
                    value={subCategory.maxSelection}
                    onChange={(event) => {
                      if (event.target.value === "sin limite") {
                        event.target.value = "";
                      }
                      changeProduct({
                        ...subCategory,
                        maxSelection: Number(event.target.value),
                      });
                    }}
                    className="input w-full"
                  >
                    {maxOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="my-4 flex items-center">
              <input
                className="input text-primary-300"
                type="checkbox"
                checked={subCategory.chargeOnlyTheMostExpensive}
                onChange={(event) => {
                  changeProduct({
                    ...subCategory,
                    chargeOnlyTheMostExpensive: event.target.checked,
                  });
                }}
              />
              <div className="block text-sm font-medium text-gray-700 pl-2">
                Cobrar solo la opción más cara
              </div>
            </div>
            <div className="mb-2 flex items-center">
              <input
                className="input text-primary-300"
                type="checkbox"
                checked={subCategory.repeatSelection}
                onChange={(event) => {
                  changeProduct({
                    ...subCategory,
                    repeatSelection: event.target.checked,
                  });
                }}
              />
              <div className="block text-sm font-medium text-gray-700 pl-2">
                Puede Repetirse
              </div>
            </div>
            <div className="flex mt-4 items-center">
              <Button text="Guardar Subcategoría" disabledProp={!subCategory.name} onClick={saveChangesSubCategory} />
            </div>
          </div>
          <div className="p-4 flex-grow bg-white rounded-lg mt-4">
            <div className="text-xl mb-2">Sub Items</div>
            <div className="flex flex-col space-y-2 lg:flex-row mb-2 lg:space-x-2 lg:space-y-0">
              <input
                className="input w-full flex-grow"
                type="text"
                placeholder="Nombre"
                onChange={(event) =>
                  setNewSubItemForm({
                    ...newSubItemForm,
                    name: event.target.value,
                  })
                }
                value={newSubItemForm.name}
              />
              <input
                className="input w-full flex-grow"
                type="number"
                placeholder="Precio ($)"
                value={newSubItemForm.price}
                onChange={(event) =>
                  setNewSubItemForm({
                    ...newSubItemForm,
                    price: event.target.value,
                  })
                }
              />
              <Button
                onClick={addNewSubItem}
                text="Agregar"
                disabledProp={!newSubItemForm.name || !newSubItemForm.price}
              />
            </div>
            <div className="text-xl my-3">Opciones Activas</div>
            <DragDropContext onDragEnd={onDragEndSubItem}>
              <Droppable droppableId="subcategories">
                {(provided: DroppableProvided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {subCategory.subItems.map(
                      (subItem: SubItem, index: number) => (
                        <Draggable
                          key={subItem.id}
                          draggableId={`id-${subItem.id}`}
                          index={index}
                        >
                          {(innerProvided) => (
                            <div
                              ref={innerProvided.innerRef}
                              {...innerProvided.draggableProps}
                              {...innerProvided.dragHandleProps}
                            >
                              <SubItemElement
                                changeVisibility={changeVisibilitySubItem}
                                subItem={subItem}
                                key={subItem.id}
                                removeSubItem={removeSubItem}
                                editSubItem={editSubItem}
                              />
                            </div>
                          )}
                        </Draggable>
                      )
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            {subCategory.subItems.length === 0 && (
              <p className="text-gray-600">
                Aun no se han cargado sub-items para esta sub-categoria
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
