import React, { useContext, useEffect, useState } from "react";
import loginService from "../services/loginService";
import { NavLink } from "react-router-dom";
import { StoreStatus } from "../types";
import logo from "../logo.svg";
import { StoreContext } from "../services/store.context";

export const SideBar: React.FC<{ storeStatus: StoreStatus }> = ({
  storeStatus,
}) => {
  const menuOptions = loginService.getMenuOptions();
  const [menuVisibility, setMenuVisibility] = useState(false);
  const [serviceStatus, setServicestatus] = useState(storeStatus);
  const { areNewsAvailable } = useContext(StoreContext);

  useEffect(() => {
    setServicestatus(storeStatus);
  }, [storeStatus]);

  return (
    <>
      <section
        className="bg-dark-grey-300 text-white pt-5 h-full hidden lg:block"
        style={{ width: "168px" }}
      >
        <img
          src={logo}
          alt=""
          className="w-30 h-10 mb-8 px-4 text-primary-100 mx-auto"
        />
        {serviceStatus && (
          <div
            className={`py-1 ${
              serviceStatus.isClosed ? "bg-red-600" : "bg-primary-300"
            } text-white w-full text-sm text-center px-4`}
          >
            {serviceStatus.message}
          </div>
        )}
        <div className="my-4 flex flex-col text-dark-grey-100">
          {menuOptions.map((option) => (
            <NavLink
              className={({ isActive }) =>
                `pl-2 pr-4 flex whitespace-no-wrap uppercase text-xs font-semibold py-2 w-full border-solid border-l-4 border-dark-grey-300 hover:border-white hover:bg-dark-grey-200 hover:text-white ${
                  isActive && "text-white"
                }`
              }
              to={option.url}
              key={option.label}
            >
              {option.label}
              {option.label === "Novedades" && areNewsAvailable && (
                <span className="flex relative h-2 w-2 ml-1">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                  <span className="relative inline-flex rounded-full h-2 w-2 bg-red-500"></span>
                </span>
              )}
            </NavLink>
          ))}
        </div>
      </section>
      <div className="lg:hidden px-4 py-2 bg-dark-grey-300 text-white">
        <div className="w-full flex justify-between items-center">
          <img src={logo} alt="" className=" w-14 h-8 flex-grow-0" />
          {serviceStatus && (
            <div
              className={`px-2 py-1 mx-2 ${
                serviceStatus.isClosed ? "bg-red-600" : "bg-primary-300"
              } text-white rounded-lg w-min whitespace-nowrap text-xs uppercase`}
            >
              {serviceStatus.message}
            </div>
          )}
          <button
            type="button"
            onClick={() => setMenuVisibility(!menuVisibility)}
            className="flex items-center p-1 text-secondary-300 hover:text-white"
          >
            <svg
              className="fill-current h-6 w-6"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        {menuVisibility && (
          <div className="px-4 flex flex-col text-dark-grey-100 absolute left-0 right-0 w-full z-50 bg-dark-grey-300">
            {menuOptions.map((option) => (
              <NavLink
                className={({ isActive }) =>
                  `whitespace-no-wrap uppercase text-sm font-semibold py-3 w-full hover:text-white ${
                    isActive && "text-white"
                  }`
                }
                to={option.url}
                key={option.label}
                onClick={() => setMenuVisibility(false)}
              >
                {option.label}
              </NavLink>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
