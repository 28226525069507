import React, { useEffect, useState, Suspense, useCallback } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { SideBar } from "../components/SideBar";
import { PopUp, PopUphandler } from "./../components/PopUp";
import { Alert } from "./../components/Alert";
import loginService, { LoginService } from "../services/loginService";
import { Button } from "../components/Button";
import apiService from "../services/api";
import { AnimatedLogo } from "../components/AnimatedLogo";
import { Spinner } from "../components/Spinner";
import { Client } from "./../services/primiscService";
import Prismic from "@prismicio/client";
import {
  Configuration,
  LocationInfo,
  StatusData,
  StoreStatus,
  STORE_STATUS,
} from "../types";
import { StoreContext } from "../services/store.context";
import NotFound from "./NotFound";
import { Modal } from "../components/Modal";
import { Wizard } from "../components/Wizard";
import { InformationCircleIcon } from "@heroicons/react/outline";

type AdminProps = {
  handleLogout: () => void;
};

export const Admin: React.FC<AdminProps> = ({ handleLogout }) => {
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const [confirmationPopUp, setConfirmationPopUp] = useState(
    {} as PopUphandler
  );
  const [locationInfo, setLocationInfo] = useState({
    name: "",
  } as LocationInfo);
  const [orderParameters, setOrdersParameters] = useState({} as Configuration);
  const [status, setStatus] = useState("idle");
  const menuOptions = loginService.getMenuOptions();
  const [serviceStatus, setServiceStatus] = useState(
    {} as { status: StoreStatus; statusData: StatusData }
  );
  const navigte = useNavigate();
  const [areNewsAvailable, setAreNewsAvailable] = useState(false);

  const doLogOut = () => {
    setConfirmationPopUp({
      handler: async (_event, confirmation: boolean) => {
        if (confirmation) {
          navigte("/");
          LoginService.logout();
          handleLogout();
        }
        setConfirmationPopUp({} as PopUphandler);
      },
      message: "Estas seguro que desea salir de la aplicación?",
    });
  };

  const updateWizardSettings = async () => {
    await apiService.putCall(
      { ...orderParameters, showWizard: false },
      "store/parameters/update"
    );
    setIsWizardOpen(false);
  };

  const updateNews = async (news: any[]) => {
    const areNews = news.filter(
      (item: any) => new Date(item.data.end) > new Date()
    );
    setAreNewsAvailable(areNews.length > 0);
  };

  const updateStore = async () => {
    const store = await apiService.getCall("store");
    setLocationInfo(store as LocationInfo);
    const parameters = await apiService.getCall("/store/parameter");
    setOrdersParameters(parameters);
  };

  useEffect(() => {
    if (locationInfo.stateV2) {
      const statusService = STORE_STATUS[locationInfo.stateV2.state];
      setServiceStatus({
        status: statusService,
        statusData: locationInfo.stateV2,
      });
    }
  }, [locationInfo]);

  useEffect(() => {
    const fetchData = async () => {
      const response = (await Client.query(
        Prismic.Predicates.at("document.type", "news")
      )) as any;
      if (response) {
        updateNews(response.results);
      }
    };
    fetchData();
  }, []);

  const handleError = useCallback(() => {
    handleLogout();
    LoginService.logout();
  }, [handleLogout]);

  useEffect(() => {
    const fetchData = async () => {
      setStatus("loading");
      const store = apiService.getCall("store");
      const parameters = apiService.getCall("/store/parameter");
      Promise.all([store, parameters])
        .then((values) => {
          setLocationInfo(values[0]);
          setOrdersParameters(values[1]);
          if (values[1].showWizard) {
            setIsWizardOpen(true);
          }
          return setStatus("loaded");
        }, handleError)
        .catch(handleError);
    };
    fetchData();
  }, [handleError]);

  const userName = LoginService.getUserName();

  if (status === "loading" || status === "idle") {
    return <AnimatedLogo />;
  }

  if (status === "error") {
    return <p>Ha habido un error porfavor intente nuevamente</p>;
  }
  return (
    <>
      <Alert
        isSoundEnabled={orderParameters.incomingOrderSoundEnabled}
        maxTime={Number(orderParameters.incomingOrderSoundMaxTime)}
      />
      {confirmationPopUp.message && (
        <PopUp
          closePopUp={confirmationPopUp.handler}
          message={confirmationPopUp.message}
        />
      )}
      <Modal
        closeModal={() => setIsWizardOpen(false)}
        isOpen={isWizardOpen}
        size="full"
        title="Bienvenido a ServiteOnline"
      >
        <div>
          <Wizard />
          <div className="mt-6 flex justify-end">
            <button
              className="btn text-white bg-primary-300"
              onClick={() => updateWizardSettings()}
            >
              No volver a mostrar
            </button>
          </div>
        </div>
      </Modal>
      <StoreContext.Provider
        value={{
          orderParameters,
          storeName: locationInfo.name,
          updateOrderParameters: updateStore,
          areNewsAvailable,
          updateNews,
        }}
      >
        <div className="flex h-screen w-screen text-base flex-col lg:flex-row z-20">
          <SideBar storeStatus={serviceStatus.status} />
          <div className="w-full bg-gray-200 h-full flex flex-col overflow-hidden">
            <section className="px-4 py-2 flex items-center justify-between bg-white border-b border-gray-700">
              <div className="flex items-center tracking-wide font-bold text-lg">
                <div className="font-semibold text-gray-600">
                  {locationInfo.name}
                </div>
              </div>
              <div className="flex-grow flex items-center justify-end">
                <div className="flex ml-3 items-center px-3 py-1 text-gray-800 space-x-2 font-semibold">
                  <span className="hidden md:block border-r-grey-300 border-r text-lg pr-2 mr-2 text-gray-600">
                    {userName}&nbsp;
                  </span>
                  <button
                    title="Información"
                    onClick={() => setIsWizardOpen(true)}
                  >
                    <InformationCircleIcon className="flex-shrink-0 h-8 hover:opacity-50 text-blue-600 cursor-pointer" />
                  </button>
                  <a
                    className="hover:opacity-80"
                    rel="noopener noreferrer"
                    title="Contáctanos"
                    target="_blank"
                    href="https://api.whatsapp.com/send?phone=+54 9 11 3949-5472"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      className="h-8 text-gray-600"
                    >
                      <path
                        d="M83 384c-13-33-35-93.37-35-128C48 141.12 149.33 48 256 48s208 93.12 208 208c0 34.63-23 97-35 128"
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="32"
                      />
                      <path
                        d="M108.39 270.13l-13.69 8h0c-30.23 17.7-31.7 72.41-3.38 122.2s75.87 75.81 106.1 58.12h0l13.69-8a16.16 16.16 0 005.78-21.87L130 276a15.74 15.74 0 00-21.61-5.87zM403.61 270.13l13.69 8h0c30.23 17.69 31.74 72.4 3.38 122.19s-75.87 75.81-106.1 58.12h0l-13.69-8a16.16 16.16 0 01-5.78-21.87L382 276a15.74 15.74 0 0121.61-5.87z"
                        fill="none"
                        stroke="currentColor"
                        strokeMiterlimit="10"
                        strokeWidth="32"
                      />
                    </svg>
                  </a>
                </div>
                <Button type="secondary" onClick={doLogOut} text="SALIR">
                  <svg
                    className="w-4 ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      fill="white"
                      d="M4.16 4.16l1.42 1.42A6.99 6.99 0 0 0 10 18a7 7 0 0 0 4.42-12.42l1.42-1.42a9 9 0 1 1-11.69 0zM9 0h2v8H9V0z"
                    />
                  </svg>
                </Button>
              </div>
            </section>
            <section className="p-4 flex-grow overflow-y-auto">
              {serviceStatus?.status.extendedMessage && (
                <div className="text-white rounded-md bg-red-400 px-2 mb-2 text-sm p-2 uppercase shadow-md font-semibold">
                  {serviceStatus.status.extendedMessage}
                </div>
              )}
              {serviceStatus?.statusData.state ===
                "OrdersLimitPeriodWarningReached" && (
                <ul className="text-white rounded-md bg-orange-400 px-2 mb-2 text-sm p-2 uppercase shadow-md font-semibold">
                  {serviceStatus.statusData.remainingDays !== null &&
                    serviceStatus.statusData.remainingDays === 1 && (
                      <li>
                        &bull;
                        {` Último día del período actual, para habilitar el proximo período
                realice el pago en la sección "Estado del Plan". `}
                      </li>
                    )}
                  {serviceStatus.statusData.percentageOfOrdersConsumed !=
                    null &&
                    serviceStatus.statusData.percentageOfOrdersConsumed > 0 && (
                      <li>
                        &bull;
                        {` Ha superado el ${serviceStatus.statusData.percentageOfOrdersConsumed}% de las ordenes disponibles. Ordenes restantes ${serviceStatus.statusData.remainingOrders}.`}
                      </li>
                    )}
                </ul>
              )}
              <Suspense
                fallback={
                  <div className="p-4">
                    <Spinner />
                  </div>
                }
              >
                <Routes>
                  <Route
                    path="/"
                    element={<Navigate to={menuOptions[0].path} />}
                  />
                  {menuOptions.map((route) => {
                    const {
                      label,
                      path,
                      component: Component,
                      ...rest
                    } = route;

                    return (
                      <Route
                        {...rest}
                        key={label}
                        path={path}
                        element={<Component />}
                      />
                    );
                  })}
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Suspense>
            </section>
          </div>
        </div>
      </StoreContext.Provider>
    </>
  );
};
