import { useEffect, useState } from "react";
import { Admin } from "./pages/Admin";
import { LoginPage } from "./pages/LoginPage";
import { ResetPassword } from "./pages/ResetPassword";
import { ForgotPassword } from "./pages/ForgotPassword";
import { LoginService } from "./services/loginService";
import { Spinner } from "./components/Spinner";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  const [user, setUser] = useState("iddle");

  const handleLogin = () => {
    setUser("logged");
  };

  const handleLogOut = () => {
    setUser("unlogged");
  };

  useEffect(() => {
    if (LoginService.isUserLogged()) {
      setUser("logged");
    } else {
      setUser("unlogged");
    }
  }, []);

  if (user === "unlogged") {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage handleLogin={handleLogin} />} />
          <Route path="reset/*" element={<ResetPassword />} />
          <Route path="forgot" element={<ForgotPassword />} />
          <Route path="*" element={<LoginPage handleLogin={handleLogin} />} />
        </Routes>
      </Router>
    );
  }

  if (user === "logged") {
    return (
      <Router>
        <Routes>
          <Route path="/*" element={<Admin handleLogout={handleLogOut} />} />
        </Routes>
      </Router>
    );
  }

  return (
    <div className="p-4">
      <Spinner />
    </div>
  );
}
export default App;
