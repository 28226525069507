import React from 'react';
import { PageTitle } from '../components/PageTitle';

const NotFound = () => {
  return (
    <>
      <PageTitle title="Página no encontrada" />
      <div className="text-xl">
        Página no encontrada, revisa que el enlace sea correcto o que tengas los
        permisos adecuados para poder acceder a esta sección.
      </div>
    </>
  );
};

export default NotFound;
