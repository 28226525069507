import React, { Fragment, useState, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { Socket } from "../services/socket";
import {
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import {
  alertService,
  AlertMessage,
} from "./../services/alertService";

type configParameters = {
  isSoundEnabled: boolean;
  maxTime: number;
};
export const Alert: React.FC<configParameters> = ({
  isSoundEnabled,
  maxTime,
}) => {
  const [alerts, setAlerts] = useState([] as AlertMessage[]);
  const [configProps, setConfigProps] = useState({ isSoundEnabled, maxTime });
  const socket = Socket.getInstance();
  useEffect(() => {
    setConfigProps({ isSoundEnabled, maxTime });
  }, [isSoundEnabled, maxTime]);

  const removeAlert = (alert: AlertMessage) => {
    setAlerts((alertsParam) => alertsParam.filter((x) => x !== alert));
  };

  const cancelAlert = (alert: AlertMessage) => {
    // remove alert
    alertService.clearSound();
    removeAlert(alert);
  };

  useEffect(() => {
    // subscribe to new alert notifications
    const subscription = alertService
      .onAlert()
      .subscribe((alert: AlertMessage) => {
        setAlerts((alertsArray) => {
          return [...alertsArray, alert];
        });
        // auto close alert if required
        if (alert.autoClose) {
          setTimeout(() => removeAlert(alert), 10000);
        }
      });

    const subscriptionSocket = socket.onOrder().subscribe({
      next: () => {
        if (configProps.isSoundEnabled) {
          alertService.playSound(configProps.maxTime);
        }
        alertService.info("Nueva Orden!");
        alertService.notifyMe("Nueva Orden!");
      },
    });
    // clean up function that runs when the component unmounts
    return () => {
      // unsubscribe & unlisten to avoid memory leaks
      subscription.unsubscribe();
      subscriptionSocket.unsubscribe();
    };
  }, [
    isSoundEnabled,
    maxTime,
    socket,
    configProps.isSoundEnabled,
    configProps.maxTime,
  ]);

  if (!alerts.length) return null;

  return (
    <div
      aria-live="assertive"
      className="fixed inset-0 top-12 flex items-end px-4 py-6 pointer-events-none sm:items-start"
    >
      <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
        {alerts.map((alert: AlertMessage) => (
          <Transition
            show={true}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    {alert.type === "Error" ? (
                      <InformationCircleIcon
                        className="h-6 w-6 text-red-400"
                        aria-hidden="true"
                      />
                    ) : (
                      <CheckCircleIcon
                        className="h-6 w-6 text-green-400"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                  <p className="ml-3 w-0 flex-1 text-gray-700">
                    {alert.message}
                  </p>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => cancelAlert(alert)}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5 text-gray-700 hover:opacity-80" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        ))}
      </div>
    </div>
  );
};
