import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  useContext,
} from "react";
import apiService from "../services/api";
import { OrderDetail } from "../components/OrderDetail";
import { PopUp } from "../components/PopUp";
import { Socket } from "../services/socket";
import { useReactToPrint } from "react-to-print";
import { NavLink } from "react-router-dom";
import { PageTitle } from "../components/PageTitle";
import { Order, Stats, AlertMessage, UserInfo } from "../types";
import whatsappService from "../services/whatsAppService";
import { OrderSection } from "../components/OrderSection";
import {
  initialStateOrdersLooader,
  ordersReducer,
} from "../services/orders.reducer";
import { alertService } from "../services/alertService";
import { StoreContext } from "../services/store.context";
import { useInterval } from "../services/useInterval";

const Orders: React.FC<{
  storeName: string;
}> = () => {
  const { orderParameters, storeName } = useContext(StoreContext);
  const [incomingOrders, setIncomingOrders] = useState([]);
  const [inProgressOrders, setInProgressOrders] = useState([]);
  const [orderToShow, setOrderToShow] = useState({} as Order);
  const [status, setStatus] = useState("idle");
  const [stats, setStats] = useState({} as Stats);
  const componentRef = useRef();
  const socket = Socket.getInstance();
  const [userInfo, setUserInfo] = useState({} as UserInfo);

  const [waMessage, setWaMessage] = useState(
    {} as {
      bodyText: string;
      conditional: boolean;
      estimatedTime: boolean;
      cancelReason: boolean;
    }
  );
  const [ordersLoading, dispatch] = useReducer(
    ordersReducer,
    initialStateOrdersLooader
  );

  const [parameters, setParameters] = useState(orderParameters);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current || null,
  });

  useEffect(() => {
    setParameters(orderParameters);
  }, [orderParameters]);

  const [alertMessage, setAlertMessage] = useState({} as AlertMessage);

  const fetchCurrent = async () => {
    apiService
      .getCall("store/stats/current")
      .then((value) => setStats(value))
      .catch(() => setStatus("error"));
  };

  const fetchData = async (
    key: "incoming" | "inprogress" | "finished",
    fn: (value: any) => void
  ) => {
    // avoid performing loading if it's already happening
    if (ordersLoading[key]) return;
    dispatch({ key, value: true });
    try {
      const response = await apiService.getCall(`order/${key}`);
      dispatch({ key, value: false });
      return fn(response);
    } catch {
      setStatus("error");
      dispatch({ key, value: false });
    }
  };

  const checkNewOrders = (orders: []) => {
    if (incomingOrders.length < orders.length) {
      alertService.info("Nueva Orden!");
      if (orderParameters.incomingOrderSoundEnabled) {
        alertService.playSound(
          Number(orderParameters.incomingOrderSoundMaxTime)
        );
      }
      setIncomingOrders(orders);
    }
  };

  useEffect(() => {
    const subscriptionSocket = socket.onOrder().subscribe({
      next: () => {
        fetchData("incoming", setIncomingOrders);
      },
    });
    return () => {
      subscriptionSocket.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  const markAsSeen = async (orderId: string) => {
    const response = await apiService.patchCall(
      { string: alertMessage.value },
      `/order/seen/${orderId}`
    );
    if (!response.error) {
      fetchData("incoming", setIncomingOrders);
    }
  };

  const confirmAction = async (
    _event: React.MouseEvent<HTMLElement, MouseEvent>,
    confirm: boolean
  ) => {
    if (confirm) {
      const response = await apiService.postCall(
        { string: alertMessage.value },
        `/order/${alertMessage.key}/${alertMessage.order.id}`
      );
      if (waMessage.conditional) {
        whatsappService.openWhatsappWithMessage({
          order: alertMessage.order,
          storeName,
          bodyText: waMessage.bodyText,
          value: alertMessage.value,
          estimatedTime: waMessage.estimatedTime,
          cancelReason: waMessage.cancelReason,
        });
      }
      if (
        alertMessage.key === "take" &&
        orderParameters.print_when_order_is_accepted
      ) {
        if (handlePrint) {
          handlePrint();
        }
      }
      if (!response.error) {
        setOrderToShow({} as Order);
        fetchData("incoming", setIncomingOrders);
        fetchData("inprogress", setInProgressOrders);
      }
    }
    setAlertMessage({} as AlertMessage);
  };

  const confirmOrder = (order: Order) => {
    setAlertMessage({
      order,
      message: "Desea Entregar esta orden?",
      key: "ready",
      value: "",
    });
    setWaMessage({
      bodyText: parameters.sms_notification_order_ready_body,
      conditional: parameters.notifyOrderReady,
      estimatedTime: false,
      cancelReason: true,
    });
  };

  const cancelOrder = (order: Order) => {
    setAlertMessage({
      order,
      message: "Desea cancelar esta orden?",
      label: "Motivo",
      key: "cancel",
      value: "",
    });
    setWaMessage({
      bodyText: parameters.sms_notification_order_canceled_body,
      conditional: parameters.notifyOrderCanceled,
      estimatedTime: false,
      cancelReason: true,
    });
  };

  const rejectOrder = (order: Order) => {
    setAlertMessage({
      order,
      message: "Desea rechazar esta orden?",
      label: "Motivo",
      key: "reject",
      value: "",
    });
    setWaMessage({
      bodyText: parameters.sms_notification_order_rejected_body,
      conditional: parameters.notifyOrderRejected,
      estimatedTime: false,
      cancelReason: true,
    });
  };

  const acceptOrder = (order: Order) => {
    const label = orderParameters.ask_for_estimated_time
      ? "Tiempo estimado de entrega"
      : undefined;

    setAlertMessage({
      order,
      message: "Desea aceptar esta orden?",
      label,
      key: "take",
      value: "",
    });
    setWaMessage({
      bodyText: parameters.sms_notification_order_accepted_body,
      conditional: parameters.notifyOrderTaken,
      estimatedTime: true,
      cancelReason: false,
    });
  };

  const getUserInfo = (userId: string) => {
    apiService
      .getCall(`stats/user/${userId}`)
      .then((value) => setUserInfo(value))
      .catch(() => setStatus("error"));
  };

  const viewInfo = (order: Order) => {
    alertService.clearSound();
    getUserInfo(order.phoneNumber);
    if (!order.seen) {
      markAsSeen(order.id);
    }
    setOrderToShow(order);
  };

  useEffect(() => {
    fetchData("incoming", setIncomingOrders);
    fetchData("inprogress", setInProgressOrders);
    fetchCurrent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Pooling to refresh orders
  useInterval(() => {
    fetchData("incoming", (orders) => {
      checkNewOrders(orders);
    });
  }, 30000);

  return (
    <>
      <PageTitle title="Órdenes">
        <div className="flex items-center justify-end">
          <NavLink to="/config">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="hover:opacity-50"
              width="34"
              height="34"
            >
              <path d="M9 4.58V4c0-1.1.9-2 2-2h2a2 2 0 0 1 2 2v.58a8 8 0 0 1 1.92 1.11l.5-.29a2 2 0 0 1 2.74.73l1 1.74a2 2 0 0 1-.73 2.73l-.5.29a8.06 8.06 0 0 1 0 2.22l.5.3a2 2 0 0 1 .73 2.72l-1 1.74a2 2 0 0 1-2.73.73l-.5-.3A8 8 0 0 1 15 19.43V20a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-.58a8 8 0 0 1-1.92-1.11l-.5.29a2 2 0 0 1-2.74-.73l-1-1.74a2 2 0 0 1 .73-2.73l.5-.29a8.06 8.06 0 0 1 0-2.22l-.5-.3a2 2 0 0 1-.73-2.72l1-1.74a2 2 0 0 1 2.73-.73l.5.3A8 8 0 0 1 9 4.57zM7.88 7.64l-.54.51-1.77-1.02-1 1.74 1.76 1.01-.17.73a6.02 6.02 0 0 0 0 2.78l.17.73-1.76 1.01 1 1.74 1.77-1.02.54.51a6 6 0 0 0 2.4 1.4l.72.2V20h2v-2.04l.71-.2a6 6 0 0 0 2.41-1.4l.54-.51 1.77 1.02 1-1.74-1.76-1.01.17-.73a6.02 6.02 0 0 0 0-2.78l-.17-.73 1.76-1.01-1-1.74-1.77 1.02-.54-.51a6 6 0 0 0-2.4-1.4l-.72-.2V4h-2v2.04l-.71.2a6 6 0 0 0-2.41 1.4zM12 16a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
            </svg>
          </NavLink>
        </div>
      </PageTitle>
      <div className="flex item-center lg:px-2 mb-2 space-x-4">
        <div className="px-3 py-2 text-gray-600 text-xs rounded-lg shadow-md border border-gray-300 bg-white w-48">
          <span>
            <span className="font-bold">
              {stats.rejectOrderCount ? stats.rejectOrderCount : "0"}{" "}
            </span>
            Orden/es Rechazadas
          </span>
        </div>
        <div className="px-3 py-2 rounded-lg shadow-md border border-gray-300 bg-white w-48 text-gray-600 text-xs">
          <span>
            <span className="font-bold">
              {stats.menuViewCount ? stats.menuViewCount : "0"}{" "}
            </span>
            Visita/s al Menú
          </span>
        </div>
      </div>
      {alertMessage.message && (
        <PopUp message={alertMessage.message} closePopUp={confirmAction}>
          {alertMessage.label && (
            <div className="w-auto mt-2">
              <label className="block text-sm font-medium text-gray-700">
                {alertMessage.label}
              </label>
              <input
                className="input"
                type="text"
                value={alertMessage.value}
                onChange={(event) => {
                  setAlertMessage({
                    ...alertMessage,
                    value: event.target.value,
                  });
                }}
              />
            </div>
          )}
        </PopUp>
      )}
      {status === "error" && (
        <div className="text-white rounded-md bg-red-400 px-2 mb-2 text-sm p-2 uppercase shadow-md font-semibold mx-0 lg:mx-2">
          Compruebe su conexión a Internet.
        </div>
      )}
      <div className="flex flex-wrap justify-between lg:space-x-2">
        <div className="grid grid-col-1 lg:grid-cols-2 w-full lg:w-auto mb-4 border-b border-gray-300">
          <OrderSection
            orders={incomingOrders}
            loading={ordersLoading.incoming}
            orderSelected={orderToShow.id}
            viewInfo={viewInfo}
          >
            <div className="w-full lg:mx-2 sm:w-48 bg-primary-200 font-semibold rounded-lg text-white px-2 py-1 flex items-center justify-center  border-2 border-white">
              NUEVAS
              {incomingOrders.length > 0 && (
                <div className="w-6 h-6 ml-3 text-white text-xs self-end rounded-full bg-primary-300 right-0 flex justify-center items-center">
                  {incomingOrders.length > 99 ? "+99" : incomingOrders.length}
                </div>
              )}
            </div>
          </OrderSection>
          <OrderSection
            orders={inProgressOrders}
            loading={ordersLoading.inprogress}
            orderSelected={orderToShow.id}
            viewInfo={viewInfo}
          >
            <div className="w-full lg:mx-2 sm:w-48 bg-orange-400 font-semibold rounded-lg text-white px-2 py-1 flex items-center justify-center  border-2 border-white">
              EN PROGRESO
              {inProgressOrders.length > 0 && (
                <div className="w-6 h-6 ml-3 text-white text-xs self-end rounded-full bg-orange-600 right-0 flex justify-center items-center">
                  {inProgressOrders.length > 99
                    ? "+99"
                    : inProgressOrders.length}
                </div>
              )}
            </div>
          </OrderSection>
        </div>
        {orderToShow.id && (
          <div className="flex-grow flex-shrink-0">
            <div className="section-border p-4 mt-2 lg:mt-0 max-w-2xl">
              <OrderDetail
                reference={componentRef}
                order={orderToShow}
                userInfo={userInfo}
              >
                <div className="flex mt-2 mb-2 justify-start space-x-3">
                  {orderToShow.statusType === "PENDING" && (
                    <button
                      type="button"
                      onClick={() => acceptOrder(orderToShow)}
                      className="btn text-xs bg-primary-200"
                    >
                      ACEPTAR
                    </button>
                  )}
                  {orderToShow.statusType === "PENDING" && (
                    <button
                      type="button"
                      onClick={() => rejectOrder(orderToShow)}
                      className="btn text-xs bg-red-600"
                    >
                      RECHAZAR
                    </button>
                  )}
                  {orderToShow.statusType === "IN_PROGRESS" && (
                    <button
                      type="button"
                      onClick={() => confirmOrder(orderToShow)}
                      className="btn text-xs bg-primary-200"
                    >
                      ENTREGAR
                    </button>
                  )}
                  {orderToShow.statusType === "IN_PROGRESS" && (
                    <button
                      type="button"
                      onClick={() => cancelOrder(orderToShow)}
                      className="btn text-xs bg-red-600"
                    >
                      CANCELAR
                    </button>
                  )}
                  {parameters.order_printing_button_enabled && (
                    <button
                      type="button"
                      onClick={handlePrint}
                      className="btn text-xs bg-blue-500"
                    >
                      IMPRIMIR
                    </button>
                  )}
                </div>
              </OrderDetail>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Orders;
