import jwt_decode from "jwt-decode";
import React from "react";
import Config from "../pages/Configuration";
import Coupon from "../pages/Coupon";
import Customization from "../pages/Customization";
import Historical from "../pages/Historical";
import Info from "../pages/Info";
import Locations from "../pages/Locations";
import Menu from "../pages/Menu";
import News from "../pages/News";
import Orders from "../pages/Orders";
import Plan from "../pages/Plan";
import Reports from "../pages/Report";
import Support from "../pages/Support";
import Users from "../pages/Users";
import api from "./api";

type MenuOptions = {
  label: string;
  role: string;
  url: string;
  path: string;
  component: React.FC<any>;
  exact: boolean;
};
export class LoginService {
  OAUTH_CLIENT_ID = "testjwtclientid";

  OAUTH_CLIENT_SECRET = "jack";

  isLogged = false;

  authorities: string[] = [];

  options: MenuOptions[] = [
    {
      label: "Órdenes",
      role: "ROLE_ORDERS",
      url: "orders",
      path: "orders",
      component: Orders,
      exact: true,
    },
    {
      label: "Menú",
      role: "ROLE_MENU_EDITION",
      url: "menu",
      path: "menu/*",
      component: Menu,
      exact: false,
    },
    {
      label: "Pedidos recientes",
      role: "ROLE_ORDER_HISTORY",
      url: "historical",
      path: "historical",
      component: Historical,
      exact: true,
    },
    {
      label: "Puntos De Venta",
      role: "ROLE_QR_GENERATION",
      url: "locations",
      path: "locations",
      component: Locations,
      exact: true,
    },
    {
      label: "Reportes",
      role: "ROLE_ORDERS",
      url: "reports",
      path: "reports",
      component: Reports,
      exact: true,
    },
    {
      label: "Comercio",
      role: "ROLE_STORE_INFO",
      url: "info",
      path: "info",
      component: Info,
      exact: true,
    },
    {
      label: "Configuración",
      role: "ROLE_CONFIGURATION",
      url: "config",
      path: "config",
      component: Config,
      exact: true,
    },
    {
      label: "Diseño",
      role: "ROLE_MENU_EDITION",
      url: "customization",
      path: "customization",
      component: Customization,
      exact: true,
    },
    {
      label: "Cupones",
      role: "ROLE_COUPON",
      url: "cupons",
      path: "cupons",
      component: Coupon,
      exact: true,
    },
    {
      label: "Usuarios",
      role: "ROLE_USERS",
      url: "users",
      path: "users",
      component: Users,
      exact: true,
    },
    {
      label: "Estado Del Plan",
      role: "ROLE_PLAN",
      url: "plan",
      path: "plan",
      component: Plan,
      exact: true,
    },
    {
      label: "Novedades",
      role: "ROLE_ADMIN",
      url: "news",
      path: "news",
      component: News,
      exact: true,
    },
    {
      label: "Soporte",
      role: "ROLE_ADMIN",
      url: "support",
      path: "support",
      component: Support,
      exact: true,
    },
  ];

  async login(form: { username: string; password: string }) {
    const data = {
      username: form.username,
      password: form.password,
      grant_type: "password",
      scope: "read write",
    };

    const auth = {
      username: this.OAUTH_CLIENT_ID,
      password: this.OAUTH_CLIENT_SECRET,
    };

    try {
      const response = await api.postFormCall(data, `oauth/token`, auth);
      if (response.error) {
        return Promise.reject(response.console.error);
      }
      this.isLogged = true;
      localStorage.setItem("tokenServiteOnline", response.access_token);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  getMenuOptions() {
    const token = localStorage.getItem("tokenServiteOnline");
    if (token) {
      const { authorities } = jwt_decode(token) as any;
      this.authorities = authorities;
      return this.options.filter((option) =>
        this.authorities.includes(option.role)
      );
    }
    return [];
  }

  static isUserLogged() {
    return !!localStorage.getItem("tokenServiteOnline");
  }

  static getUserName() {
    const token = localStorage.getItem("tokenServiteOnline");
    if (token) {
      const decoded: any = jwt_decode(token);
      return decoded.user_name;
    }
    return "";
  }

  static logout() {
    localStorage.removeItem("tokenServiteOnline");
  }
}

const loginService = new LoginService();
export default loginService;
