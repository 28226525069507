export const initialStateOrdersLooader = {
  incoming: false,
  inprogress: false,
  finished: false,
};
export const ordersReducer = (
  state: {
    incoming: boolean;
    inprogress: boolean;
    finished: boolean;
  },
  action: { key: string; value: boolean }
) => {
  switch (action.key) {
    case 'incoming':
      return { ...state, incoming: action.value };
    case 'inprogress':
      return { ...state, inprogress: action.value };
    case 'finished':
      return { ...state, finished: action.value };
    default:
      throw new Error('Unexpected action');
  }
};
