import React from 'react';
import { Order } from '../types';
import { OrderCard } from './OrderCard';
import { Spinner } from './Spinner';

type OrderSectionProps = {
  orders: Order[];
  orderSelected: string;
  viewInfo: (order: Order) => void;
  loading: boolean;
};
export const OrderSection: React.FC<OrderSectionProps> = ({
  orders,
  orderSelected,
  viewInfo,
  loading,
  children,
}) => {
  return (
    <div className="w-full overflow-x-hidden mt-2 lg:mt-0">
      {children}
      <div
        className="flex lg:block p-4 px-0 lg:px-2 space-x-4 lg:space-x-0 lg:space-y-2 overflow-x-auto"
        style={{ maxHeight: 'calc(100vh - 254px)' }}
      >
        {orders.map((order: Order) => (
          <OrderCard
            key={order.id}
            viewInfo={viewInfo}
            order={order}
            isOrderSelected={orderSelected === order.id}
          />
        ))}
        {!loading && orders.length === 0 && (
          <div className="px-3 py-2 flex-shrink-0 text-sm w-48 text-center text-gray-800 section-border">
            Sin órdenes
          </div>
        )}
        {loading && (
          <div className="p-10 text-center hidden sm:block">
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};
