import React from "react";
import { PageTitle } from "../components/PageTitle";

const News = () => {
  return (
    <div>
      <PageTitle title="Soporte" />
      <div className="section-border p-4">
        <p className="text-gray-700">
          En caso de tener algún problema con la aplicación o dudas respecto a
          su funcionamiento, no dudes en ponerte en contacto con cualquiera de
          nuestras vías de comunicación.
        </p>
        <div className="mt-4">
          <span className="text-gray-700 font-semibold">Email :</span>
          <a
            className="px-2 text-primary-300 hover:opacity-80 hover:underline"
            href="mailto:info@serviteonline.com"
          >
            info@serviteonline.com
          </a>
        </div>
        <div className="mt-1">
          <span className="text-gray-700 font-semibold">Whatsapp :</span>
          <a
            className="px-2 text-primary-300 hover:opacity-80 hover:underline"
            rel="noopener noreferrer"
            target="_blank"
            href="https://api.whatsapp.com/send?phone=+549113949-5472"
          >
            (+54)911-39495472
          </a>
        </div>
        <div className="mt-1">
          <span className="text-gray-700 font-semibold">Instagram :</span>
          <a
            className="px-2 text-primary-300 hover:opacity-80 hover:underline"
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.instagram.com/serviteonline/"
          >
            https://www.instagram.com/serviteonline/
          </a>
        </div>
        <div className="mt-1">
          <span className="text-gray-700 font-semibold">Web :</span>
          <a
            className="px-2 text-primary-300 hover:opacity-80 hover:underline"
            href="https://www.serviteonline.com"
          >
            www.serviteonline.com
          </a>
        </div>
        <div className="mt-1">
          <span className="text-gray-700 font-semibold">Linkedin:</span>
          <a
            className="px-2 text-primary-300 hover:opacity-80 hover:underline"
            href="https://www.linkedin.com/company/serviteonline"
          >
            https://www.linkedin.com/company/serviteonline
          </a>
        </div>
      </div>
    </div>
  );
};

export default News;
