import React, { useEffect, useState } from "react";
import apiService from "../services/api";
import { Spinner } from "./Spinner";
import { format, subYears, addYears } from "date-fns";
import DatePicker from "react-datepicker";

type User = {
  phoneNumber: string;
  total: number;
  lastNameUser: string;
};

const YearlyReportCustomers: React.FC = () => {
  const [loading, setLoading] = useState("idle");
  const [reportData, setReportData] = useState([] as User[]);

  const [dateSelected, setDateSelected] = useState(new Date());

  useEffect(() => {
    const fetchData = async () => {
      setLoading("loading");
      try {
        const formattedDate = format(new Date(dateSelected), "yyyy");
        const response = await apiService.getCall(
          `stats/user/top/year/COUNT/${formattedDate}/20`
        );
        setReportData(response);
        setLoading("loaded");
      } catch (error) {
        setLoading("error");
      }
    };
    fetchData();
  }, [dateSelected]);

  return (
    <>
      <div className="flex w-full items-center mb-3 space-x-1 justify-between">
        <div className="flex items-center w-auto">
          <DatePicker
            className="input form-input w-32"
            selected={dateSelected}
            maxDate={new Date()}
            onChange={(date) => setDateSelected(date as Date)}
            dateFormat="yyyy"
            showYearPicker
          />
          <svg
            className="h-6 w-6 text-gray-800"
            style={{ transform: "translateX(-36px)" }}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
        </div>
        <div className="flex rounded-lg shadow bg-white cursor-pointer">
          <button
            className="flex items-center p-2 border-r border-gray-500 hover:opacity-50"
            onClick={() => setDateSelected(subYears(dateSelected, 1))}
          >
            <svg
              className="fill-current h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 26 26"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline fill="none" points="15 18 9 12 15 6"></polyline>
            </svg>
          </button>
          <button
            className="flex items-center p-2 hover:opacity-50"
            onClick={() => setDateSelected(addYears(dateSelected, 1))}
          >
            <svg
              className="fill-current h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline fill="none" points="9 18 15 12 9 6"></polyline>
            </svg>
          </button>
        </div>
      </div>
      {loading === "error" && (
        <div className="text-white p-4 rounded-lg shadow-md bg-red-500">
          Error al cargar información, revise la fecha seleccionada o intente
          volver a cargar la página.
        </div>
      )}
      {loading === "loading" && <Spinner />}
      {loading === "loaded" && (
        <div className="mt-2">
          {reportData.length > 0 ? (
            reportData.map((customer: User, index) => (
              <div className="flex justify-between mb-1" key={index}>
                <div>{customer.phoneNumber}</div>
                <div className="font-semibold">{customer.total}</div>
              </div>
            ))
          ) : (
            <div className="pt-2 text-sm text-gray-600">
              No han habido ventas este mes.
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default YearlyReportCustomers;
