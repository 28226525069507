import React from "react";
import { Order, PaymentMethod } from "../types";

export interface OrderCardProps {
  order: Order;
  isOrderSelected: boolean;
  viewInfo: (order: Order) => void;
}

export const OrderCard = ({
  order,
  isOrderSelected,
  viewInfo,
}: OrderCardProps) => {
  return (
    <div
      aria-hidden="true"
      className={`px-3 py-1.5 flex-shrink-0 w-48 section-border hover:scale-105 transform duration-300 cursor-pointer ${
        isOrderSelected && "border-primary-200 border-l-4"
      }`}
      onClick={() => viewInfo(order)}
    >
      {!order.seen && (
        <div className="px-2 bg-primary-100 text-xs absolute rounded-md text-white -top-1.5 right-0 animate-pulse">
          Nueva
        </div>
      )}
      <div className="flex justify-between items-center text-sm">
        <div className="font-bold text-gray-700">#{order.storeOrderId}</div>
        <div className="font-semibold">${order.price}</div>
      </div>
      <div className="text-sm text-gray-700">{order.elapsedTime}</div>
      <div className="flex justify-between items-center text-gray-700 mt-1">
        <div className="text-xs bg-gray-300 text-black inline px-1 rounded-sm">
          {order.locationName}
        </div>
        {(order.paymentDTO.paymentMethod !== PaymentMethod.cash && order.paymentDTO.paymentMethod !== PaymentMethod.addToAccount) && (
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              className="w-4"
            >
              <path d="M18 6V4H2v2h16zm0 4H2v6h16v-6zM0 4c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm4 8h4v2H4v-2z" />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
};
