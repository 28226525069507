import React, { useEffect, useState } from "react";
import Prismic from "@prismicio/client";
import { RichText } from "prismic-reactjs";
import { Client } from "./../services/primiscService";
import { ArrowSmLeftIcon, ArrowSmRightIcon } from "@heroicons/react/solid";
import { Spinner } from "./Spinner";

type WizardProps = {};

type Slides = {
  data: { img: { url: string }; description: any; title: any; order: number };
  uid: string;
};

export const Wizard: React.FC<WizardProps> = () => {
  const [slideActive, setSlideActive] = useState(0);
  const [loading, setLoading] = useState('idle');
  const [slides, setSlides] = useState([] as Slides[]);

  useEffect(() => {
    setLoading('loading');
    const fetchData = async () => {
      const response = (await Client.query(
        Prismic.Predicates.at("document.type", "wizard"),
        { orderings: "[my.wizard.order]" }
      )) as any;
      if (response) {
        setSlides(response.results);
      }
      setLoading('resolved');
    };
    fetchData();
  }, []);

  if (loading === 'loading') {
    return <div className="container p-8">
      <Spinner />
    </div>;
  }

  return (
    <>
      <div className="container">
        <div className="relative overflow-x-hidden w-full">
          {slides
            .filter((slide, index) => slideActive === index)
            .map((slide) => (
              <div className="bg-white w-full my-8">
                <div className="w-full mb-6">
                  <h1 className="text-3xl font-medium tracking-wide text-gray-800 dark:text-white md:text-4xl">
                    {RichText.asText(slide.data["title"])}
                  </h1>
                  <p className="mt-4 text-gray-600 dark:text-gray-300 whitespace-pre-line">
                    {RichText.asText(slide.data["description"])}
                  </p>
                </div>
                <img
                  alt={slide.data["title"].text}
                  className="m-auto object-contain w-full h-full max-w-2xl rounded-md"
                  src={slide.data.img.url}
                />
              </div>
            ))}
        </div>
        <div className="flex justify-center items-center space-x-2 my-4">
          <ArrowSmLeftIcon
            className="h-12 w-12 cursor-pointer hover:opacity-75 text-primary-300"
            aria-hidden="true"
            onClick={() =>
              setSlideActive((slide) => (slide > 0 ? slide - 1 : slide))
            }
          />
          {slides.map((slide, index) => (
            <button
              key={slide.uid}
              onClick={() => setSlideActive(index)}
              className={`w-3 h-3 mx-2 ${
                slideActive === index
                  ? "bg-primary-300"
                  : "bg-gray-300 hover:bg-primary-300"
              } rounded-full md:mx-0 focus:outline-none`}
            ></button>
          ))}
          <ArrowSmRightIcon
            className="h-12 w-12 cursor-pointer hover:opacity-75 text-primary-300"
            aria-hidden="true"
            onClick={() => {
              setSlideActive(
                slideActive < slides.length - 1 ? slideActive + 1 : slideActive
              );
            }}
          />
        </div>
      </div>
    </>
  );
};
