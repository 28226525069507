import React, { useEffect, useState } from "react";
import { Button } from "../components/Button";
import { Modal } from "../components/Modal";
import { PageTitle } from "../components/PageTitle";
import { Spinner } from "../components/Spinner";
import apiService from "../services/api";
import { ConfirmationPlan, PlanStatus, StorePlan } from "../types";

const Plan = () => {
  const [planStatus, setPlanStatus] = useState({} as PlanStatus);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    options: {} as ConfirmationPlan,
  });
  const [status, setStatus] = useState("idle");

  useEffect(() => {
    const fetchData = async () => {
      setStatus("loading");
      const response: PlanStatus = await apiService.getCall(
        "registration/billing/status"
      );
      setPlanStatus(response);
      setStatus("resolved");
    };
    fetchData();
  }, []);

  const selectPlan = async (plan: StorePlan) => {
    const response = await apiService.putCall(
      { periodOption: "current", planName: plan.name },
      "registration/plan/options"
    );
    setModalOpen(false);
    if (!response.error) {
      setConfirmationModal({
        show: true,
        options: { ...response, newPlan: plan.name },
      });
    }
  };

  if (status === "idle" || status === "loading") {
    return (
      <div className="p-4">
        <Spinner />
      </div>
    );
  }

  const row = (key: string | number | Date, label: string) =>
    key !== null ? (
      <div className="flex items-center">
        <div className="font-semibold">{label}:&nbsp;</div>
        <div className="text-gray-700">{key}</div>
      </div>
    ) : null;

  const payNewPlan = async () => {
    const response = await apiService.putCall(
      { periodOption: "current", planName: confirmationModal.options.newPlan },
      "registration/plan/update"
    );
    if (!response.error && response.string) {
      window.open(response.string);
    }
    setConfirmationModal({
      show: false,
      options: {} as ConfirmationPlan,
    });
  };

  const payCurrentPlan = async () => {
    const response = await apiService.putCall({}, "registration/plan/pay");
    if (!response.error && response.string) {
      window.open(response.string, "_blank");
    }
  };

  return (
    <div>
      <Modal
        isOpen={confirmationModal.show}
        closeModal={() =>
          setConfirmationModal({
            show: false,
            options: {} as ConfirmationPlan,
          })
        }
        title="Pago"
      >
        <div>
          Estas por pagar <b>${confirmationModal.options.amount}</b>{" "}
          correspondientes al plan <b> {confirmationModal.options.newPlan} </b>{" "}
          para el período del
          <b>
            {" "}
            {new Date(
              confirmationModal.options.periodFrom
            ).toLocaleDateString()}{" "}
          </b>
          al
          <b>
            {" "}
            {new Date(
              confirmationModal.options.periodTo
            ).toLocaleDateString()}{" "}
          </b>
          . <br />
          Una vez recibido el pago, el plan se activara automáticamente.
          <div className="mt-2">
            <Button
              onClick={() => {
                payNewPlan();
              }}
              type="secondary"
              text="pagar"
            />
          </div>
        </div>
      </Modal>
      <Modal
        closeModal={() => setModalOpen(false)}
        size="4xl"
        isOpen={modalOpen}
        title="Cambio de Plan de Servicios"
      >
        <div>
          <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
            <thead>
              <tr>
                <th className="text-left p-2">Nombre</th>
                <th className="text-left p-2">Ordenes por mes</th>
                <th className="text-left p-2">Puntos de venta</th>
                <th className="text-left p-2">Admins</th>
                <th className="text-left p-2">Personalización</th>
                <th className="text-right p-2">Precio ($)</th>
                <th className="text-right p-2">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {planStatus.storePlans.map((plan: StorePlan) => (
                <tr
                  className="border-gray-300 border-t border-b"
                  key={plan.name}
                >
                  <td className="p-2">{plan.name}</td>
                  <td className="p-2">{plan.monthlyOrderLimit}</td>
                  <td className="p-2">{plan.posLimit}</td>
                  <td className="p-2">{plan.usersLimit}</td>
                  <td className="p-2">
                    {plan.features.includes("pwaPersonalization") ? "si" : "no"}
                  </td>
                  <td className="p-2">${plan.price}</td>
                  <td className="p-2 text-right">
                    {plan.name !== planStatus.currentPeriod.planName && (
                      <Button
                        onClick={() => selectPlan(plan)}
                        type="secondary"
                        text="contratar"
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal>
      <PageTitle title="Estado de la cuenta" />
      <div className="section-border p-6">
        <div className="text-xl mb-2 font-semibold border-b border-gray-600 pb-2">
          Estado Actual de la Subscripción
        </div>
        <p className="text-gray-600 mb-3">
          Los siguientes datos corresponden al estado actual de la cuota al día
          de la fecha.
        </p>
        {planStatus.currentPeriod && (
          <div>
            {row(planStatus.currentPeriod.planName, "Nombre del Plan")}
            {row(planStatus.currentPeriod.planPrice, "Precio ($)")}
            {planStatus.currentPeriod.periodFromDate !== null
              ? row(
                  new Date(
                    planStatus.currentPeriod.periodFromDate
                  ).toLocaleDateString(),
                  "Comienzo del período"
                )
              : null}
            {planStatus.currentPeriod.periodToDate !== null
              ? row(
                  new Date(
                    planStatus.currentPeriod.periodToDate
                  ).toLocaleDateString(),
                  "Fin del período"
                )
              : null}
            {planStatus.currentPeriod.expiration !== null
              ? row(
                  new Date(
                    planStatus.currentPeriod.expiration
                  ).toLocaleString(),
                  "Expiración"
                )
              : null}
            {row(planStatus.currentPeriod.orderLimit, "Limite de ordenes")}
            {row(planStatus.currentPeriod.currentOrders, "Ordenes realizadas")}
          </div>
        )}{" "}
        <div className="flex mt-3 items-center">
          <Button
            onClick={() => setModalOpen(true)}
            text={
              planStatus.currentPeriod.freePeriod ||
              planStatus.currentPeriod.planName === "Sin Plan"
                ? "Seleccionar Plan"
                : "Agregar órdenes al plan"
            }
          />
        </div>
      </div>
      {planStatus.nextPeriod && (
        <div className="bg-white p-4 mt-3">
          <div className="text-xl mb-2 font-semibold border-b border-gray-600 pb-2">
            Siguiente Cuota
          </div>
          <p className="text-gray-600 mb-3">
            Fecha de inicio y vencimiento de la siguiente cuota de la
            subscripción.
          </p>
          <div>
            {row(planStatus.nextPeriod.planName, "Nombre del Plan")}
            {row(planStatus.nextPeriod.planPrice, "Precio ($)")}
            {row(
              new Date(planStatus.nextPeriod.periodFromDate).toLocaleString(),
              "Comienzo del período"
            )}
            {row(
              new Date(planStatus.nextPeriod.periodToDate).toLocaleString(),
              "Fin del período"
            )}
            {row(planStatus.nextPeriod.orderLimit, "Limite de ordenes")}
            {row(planStatus.nextPeriod.currentOrders, "Ordenes realizadas")}
            <div className="flex items-center">
              <div className="font-semibold">Pagado:&nbsp;</div>
              <div
                className={
                  planStatus.nextPeriod.paid
                    ? "text-primary-300"
                    : "text-red-600"
                }
              >
                {planStatus.nextPeriod.paid ? "SI" : "NO"}
              </div>
            </div>
          </div>
          {!planStatus.nextPeriod.paid && (
            <div className="flex mt-3 items-center">
              <Button
                type="secondary"
                text="pagar siguiente cuota"
                onClick={() => payCurrentPlan()}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Plan;
