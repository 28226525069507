import React from "react";
import "./Menu.css";
import { MenuItems } from "../components/MenuItems";
import { ProductCustomization } from "./ProductCustomization";
import { Routes, Route } from "react-router-dom";

const Menu: React.FC = () => {
  return (
    <Routes>
      <Route
        path=":sectionId/:productId/customize"
        element={<ProductCustomization editMode />}
      />
      <Route path=":sectionId" element={<MenuItems />} />
      <Route path="/" element={<MenuItems />} />
    </Routes>
  );
};

export default Menu;
