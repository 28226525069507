import { useEffect, useRef } from "react";

export function useInterval(callback: any, delay: any) {
  const savedCallback = useRef() as any;

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    let id = setInterval(tick, delay);
    return () => {
      clearInterval(id);
    }
  }, [delay]);
}