import React, { useState, useEffect } from "react";
import { SubCategoryList } from "../components/SubCategoryList";
import { SubCategoryDetails } from "../components/SubCategoryDetails";
import apiService from "../services/api";
import { useParams, NavLink } from "react-router-dom";
import { Spinner } from "../components/Spinner";
import { PageTitle } from "../components/PageTitle";
import { Product, SubCategory } from "../types";
import { ImgLoader } from "../components/ImgLoader";
import { Button } from "../components/Button";
import { alertService } from "../services/alertService";

type ProductProps = {
  editMode?: boolean;
};

export const ProductCustomization: React.FC<ProductProps> = () => {
  const { productId, sectionId } = useParams<{
    productId: string;
    sectionId: string;
  }>();
  const [status, setStatus] = useState("idle");
  const [productForm, setProductForm] = useState({
    name: "",
    description: "",
    imageLink: "",
    price: 0,
    hidden: false,
    itemImage: {
      large: "",
      small: "",
    },
  });
  const [product, setProduct] = useState({} as Product);
  const [subCategorySelected, setSubcategorySelected] = useState(
    {} as SubCategory
  );

  const saveChanges = async (event: any) => {
    event.preventDefault();
    setStatus("loading");
    try {
      const response = await apiService.putCall(
        productForm,
        "menu/item/update"
      );
      if (response.error) {
        alertService.error(
          "Ha habido un error, por favor revise la información e intente nuevamente"
        );
        setStatus("resolved");
        return;
      }
      await reloadItem();
      setStatus("resolved");
    } catch (error) {
      alertService.error("Ha habido un error, por favor intente nuevamente");
      setStatus("resolved");
    }
  };

  const reloadItem = async () => {
    const response = await apiService.getCall(`menu/item/${productId}`);
    setProduct(response);
    setProductForm(response);
    const subCategory = response.subItemsCategories.find(
      (subcategory: SubCategory) => subcategory.id === subCategorySelected.id
    );
    if (subCategory) {
      setSubcategorySelected(subCategory);
    } else {
      setSubcategorySelected({} as SubCategory);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setStatus("loading");
      const response = await apiService.getCall(`menu/item/${productId}`);
      setProduct(response);
      setProductForm(response);
      if (response.subItemsCategories.length > 0) {
        setSubcategorySelected(response.subItemsCategories[0]);
      }
      setStatus("resolved");
    };
    if (productId && sectionId) {
      fetchData();
    }
  }, [productId, sectionId]);

  if (status === "idle" || status === "loading") {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <PageTitle title="Edición del producto">
        <NavLink
          className="text-blue-500 text-l cursor-pointer hover:opacity-75 block uppercase tracking-wide font-bold hover:underline"
          to={`/menu/${sectionId}`}
        >
          ← VOLVER AL MENÚ
        </NavLink>
      </PageTitle>
      <div className="bg-white rounded-lg p-4 mb-4">
        <form onSubmit={(event) => saveChanges(event)}>
          <div className="grid grid-cols-1 md:flex gap-2 md:space-x-4 md:justify-between">
            <div className="flex-grow w-64">
              <div className="mb-4 flex space-x-4">
                <div className="flex-grow">
                  <label
                    htmlFor="nombre"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Nombre
                  </label>
                  <input
                    disabled={status === "loading" || status === "idle"}
                    type="text"
                    name="nombre"
                    className="input w-full"
                    maxLength={50}
                    value={productForm.name}
                    onChange={(event) => {
                      setProductForm({
                        ...productForm,
                        name: event.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  <label
                    htmlFor="precio"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Precio ($)
                  </label>
                  <input
                    disabled={status === "loading" || status === "idle"}
                    type="number"
                    min="0"
                    className="input w-full"
                    value={productForm.price}
                    onChange={(event) => {
                      setProductForm({
                        ...productForm,
                        price: parseInt(event.target.value, 10),
                      });
                    }}
                  />
                </div>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="descripcion"
                  className="block text-sm font-medium text-gray-700"
                >
                  Descripción
                </label>
                <textarea
                  name="descripcion"
                  value={productForm.description}
                  className="input w-full"
                  onChange={(event) => {
                    setProductForm({
                      ...productForm,
                      description: event.target.value,
                    });
                  }}
                />
              </div>
              <div className="mb-4">
                <div className="mb-2 flex items-center">
                  <input
                    disabled={status === "loading" || status === "idle"}
                    type="checkbox"
                    className="input text-primary-300 mr-3"
                    checked={productForm.hidden}
                    onChange={(event) => {
                      setProductForm({
                        ...productForm,
                        hidden: event.target.checked,
                      });
                    }}
                  />
                  <label className="block text-sm font-medium text-gray-700">
                    Ocultar Producto del Menu
                  </label>
                </div>
              </div>
            </div>
            <div className="flex-grow">
              <ImgLoader
                serverImg={productForm.itemImage.large}
                pictureUploaded={(url: string) =>
                  setProductForm({ ...productForm, imageLink: url })
                }
                pictureDeleted={() =>
                  setProductForm({
                    ...productForm,
                    imageLink: "",
                    itemImage: { large: "", small: "" },
                  })
                }
              />
            </div>
          </div>
          <div className="flex items-center">
            <Button text="Guardar Producto" isButtonForm />
          </div>
        </form>
      </div>
      <PageTitle title="Personalizar extras" />
      <div className="grid gap-4 grid-rows-1 grid-cols-1 lg:grid-cols-2">
        <SubCategoryList
          product={product}
          reloadItem={reloadItem}
          changeProduct={setProduct}
          selected={subCategorySelected.id}
          clickCategory={setSubcategorySelected}
        />
        {subCategorySelected.id && (
          <SubCategoryDetails
            subCategory={subCategorySelected}
            reloadItem={reloadItem}
            changeProduct={setSubcategorySelected}
          />
        )}
      </div>
    </>
  );
};
