import React, { useEffect, useState } from "react";
import apiService from "../services/api";
import { Spinner } from "./Spinner";
import { format, subMonths, addMonths } from "date-fns";
import DatePicker from "react-datepicker";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";

type Item = {
  itemName: string;
  count: number;
};
type SubItem = {
  itemName: string;
  count: number;
  subItemName: string;
};
export type ReportType = {
  totalAmount: number;
  totalCount: number;
  totalDeliveryCost: number;
  items: Item[];
  subItems: SubItem[];
  menuViewCount: number;
  rejectOrderCount: number;
  averagePricePerOrder: number;
  dailyBalanceStats: any;
  amountPercentageChangeFromPreviousMonth: number;
  countPercentageChangeFromPreviousMonth: number;
  previousMonthTotalAmount: number;
  previousMonthTotalCount: number;
};
const MonthlyReport: React.FC = () => {
  const [loading, setLoading] = useState("idle");
  const [reportData, setReportData] = useState({} as ReportType);

  const [dateSelected, setDateSelected] = useState(new Date());

  useEffect(() => {
    const fetchData = async () => {
      setLoading("loading");
      try {
        const formattedDate = format(new Date(dateSelected), "yyyy-MM");
        const response = await apiService.getCall(
          `/stats/store/monthly/${formattedDate}`
        );
        setReportData({
          ...response,
          items: response.items.filter((item: Item) => item.count > 0),
          subItems: response.subItems.filter(
            (subItem: SubItem) => subItem.count > 0
          ),
        });
        setLoading("loaded");
      } catch (error) {
        setLoading("error");
      }
    };
    fetchData();
  }, [dateSelected]);

  return (
    <>
      <div className="flex items-center mb-3 space-x-4">
        <div className="flex items-center w-auto">
          <DatePicker
            className="input form-input w-56"
            selected={dateSelected}
            maxDate={new Date()}
            onChange={(date) => setDateSelected(date as Date)}
            dateFormat="yyyy-MM"
            showMonthYearPicker
          />
          <svg
            className="h-6 w-6 text-gray-800"
            style={{ transform: "translateX(-36px)" }}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
        </div>
        <div className="flex rounded-lg shadow bg-white mr-4 cursor-pointer">
          <button
            className="flex items-center p-2 border-r border-gray-500 hover:opacity-50"
            onClick={() => setDateSelected(subMonths(dateSelected, 1))}
          >
            <svg
              className="fill-current h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 26 26"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline fill="none" points="15 18 9 12 15 6"></polyline>
            </svg>
          </button>
          <button
            className="flex items-center p-2 hover:opacity-50"
            onClick={() => setDateSelected(addMonths(dateSelected, 1))}
          >
            <svg
              className="fill-current h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline fill="none" points="9 18 15 12 9 6"></polyline>
            </svg>
          </button>
        </div>
      </div>
      {loading === "error" && (
        <div className="text-white p-4 rounded-lg shadow-md bg-red-500 w-max">
          Error al cargar información, revise la fecha seleccionada o intente
          volver a cargar la página.
        </div>
      )}
      {loading === "loading" && <Spinner />}
      {loading === "loaded" && (
        <div>
          <h2 className="text-xl my-2">Estadísticas generales</h2>
          <div
            className="grid gap-4 item-center mb-2"
            style={{
              gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
            }}
          >
            <div className="px-3 py-2 rounded-lg shadow border border-gray-300 bg-white">
              <div className="text-gray-600 text-xs font-semibold">
                Total vendido
              </div>
              <div className="flex justify-between items-center">
                {"$" + reportData.totalAmount.toFixed(2)}
                <span
                  className={`${
                    reportData.amountPercentageChangeFromPreviousMonth > 0
                      ? "bg-green-300 text-green-700"
                      : "bg-red-300 text-red-700"
                  } rounded w-min px-1.5 text-xs py-0.5 font-bold`}
                >
                  {reportData.amountPercentageChangeFromPreviousMonth > 0
                    ? `↑${reportData.amountPercentageChangeFromPreviousMonth.toFixed(
                        0
                      )}%`
                    : `↓${(
                        reportData.amountPercentageChangeFromPreviousMonth * -1
                      ).toFixed(0)}%`}
                </span>
              </div>
              <div className="text-xs text-gray-600">
                Mes anterior ${reportData.previousMonthTotalAmount}
              </div>
            </div>
            <div className="px-3 py-2 rounded-lg shadow border border-gray-300 bg-white">
              <div className="text-gray-600 text-xs font-semibold">
                Total de órdenes
              </div>
              <div className="flex justify-between items-center">
                {reportData.totalCount}
                <span
                  className={`${
                    reportData.countPercentageChangeFromPreviousMonth > 0
                      ? "bg-green-300 text-green-700"
                      : "bg-red-300 text-red-700"
                  } rounded w-min px-1.5 text-xs py-0.5 font-bold`}
                >
                  {reportData.countPercentageChangeFromPreviousMonth > 0
                    ? `↑${reportData.countPercentageChangeFromPreviousMonth.toFixed(
                        0
                      )}%`
                    : `↓${(
                        reportData.countPercentageChangeFromPreviousMonth * -1
                      ).toFixed(0)}%`}
                </span>
              </div>
              <div className="text-xs text-gray-600">
                Mes anterior {reportData.previousMonthTotalCount}
              </div>
            </div>
            <div className="px-3 py-2 rounded-lg shadow border border-gray-300 bg-white">
              <div className="text-gray-600 text-xs font-semibold">
                Total de visitas al menú
              </div>
              <div className="pr-1">{reportData.menuViewCount}</div>
            </div>
            <div className="px-3 py-2 rounded-lg shadow border border-gray-300 bg-white">
              <div className="text-gray-600 text-xs font-semibold">
                Total de órdenes rechazadas
              </div>
              <div className="pr-1">{reportData.rejectOrderCount}</div>
            </div>
            <div className="px-3 py-2 rounded-lg shadow border border-gray-300 bg-white">
              <div className="text-gray-600 text-xs font-semibold">
                Monto promedio de órdenes
              </div>
              <div className="pr-1">
                {"$" + reportData.averagePricePerOrder.toFixed(2)}
              </div>
            </div>
            <div className="px-3 py-2 rounded-lg shadow border border-gray-300 bg-white">
              <div className="text-gray-600 text-xs font-semibold">
                Total de delivery
              </div>
              <div className="pr-1">
                {"$" + reportData.totalDeliveryCost.toFixed(2)}
              </div>
            </div>
          </div>
          <div className="hidden md:block w-100 h-96 mt-10">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width={500}
                height={370}
                data={reportData.dailyBalanceStats}
                margin={{
                  top: 30,
                  right: 30,
                  left: 0,
                  bottom: 10,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="dayOfMonth" label="Día del mes" height={70} />
                <YAxis yAxisId="left" />
                <YAxis yAxisId="right" orientation="right" name="$" />
                <Tooltip />
                <Legend />
                <Line
                  yAxisId="left"
                  type="monotone"
                  name="Cantidad de órdenes"
                  dataKey="totalCount"
                  stroke="#658EA9"
                  activeDot={{ r: 8 }}
                />
                <Line
                  yAxisId="left"
                  type="monotone"
                  name="Órdenes rechazadas"
                  dataKey="rejectOrderCount"
                  stroke="#74BDCB"
                  activeDot={{ r: 8 }}
                />
                <Line
                  yAxisId="left"
                  type="monotone"
                  name="Vistas al menú"
                  dataKey="menuViewCount"
                  stroke="#FFA384"
                  activeDot={{ r: 8 }}
                />
                <Line
                  yAxisId="right"
                  type="monotone"
                  name="Total vendido $"
                  dataKey="totalAmount"
                  stroke="#2F4A60"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <h2 className="text-xl mt-6 mb-4">Productos más vendidos</h2>
          <div className="mt-2 rounded-lg shadow border border-gray-300 bg-white">
            {reportData.items.length > 0 ? (
              <div className="pb-2">
                <div className="p-2 mt-1 flex justify-between border-b border-gray-200 text-gray-800 font-semibold">
                  <div>Producto</div>
                  <div>Cantidad vendida</div>
                </div>
                {reportData.items.map((item) => (
                  <div key={item.itemName} className="px-2 py-1 flex justify-between text-gray-700 border-t first-of-type:border-none border-gray-100">
                    <div className="break-all pr-2">{item.itemName}</div>
                    <div className="pr-2">{item.count}</div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="p-4 text-sm text-gray-600">
                No existe registro de productos vendidos para la fecha
                seleccionada.
              </div>
            )}
          </div>
          <h2 className="text-xl mt-6 mb-4">Sub Items más vendidos</h2>
          <div className="mt-2 rounded-lg shadow border border-gray-300 bg-white">
            {reportData.subItems.length > 0 ? (
              <div className="pb-2">
                <div className="p-2 mt-1 flex justify-between border-b border-gray-200 text-gray-800 font-semibold">
                  <div>Producto</div>
                  <div>Cantidad vendida</div>
                </div>
                {reportData.subItems.map((subItem) => (
                  <div className="px-2 py-1 flex justify-between text-gray-700 border-t first-of-type:border-none border-gray-100">
                    <div className="break-all pr-2">
                      {subItem.subItemName}&nbsp;
                      <span className="text-xs text-gray-600">
                        {subItem.itemName}
                      </span>
                    </div>
                    <div className="pr-2">{subItem.count}</div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="p-4 text-sm text-gray-600">
                No existe registro de subitems vendidos para la fecha
                seleccionada.
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default MonthlyReport;
