import React, { useEffect, useState } from "react";
import apiService from "../services/api";
import { Modal } from "../components/Modal";
import { Spinner } from "../components/Spinner";
import { PageTitle } from "../components/PageTitle";
import { Button } from "../components/Button";
import { User } from "../types";
import { alertService } from "../services/alertService";
import { PencilAltIcon, TrashIcon } from "@heroicons/react/outline";

const Users = () => {
  const [users, setUsers] = useState([] as User[]);
  const [status, setStatus] = useState("idle");
  const [errorMessage, setErrorMessage] = useState("");
  const [roles, setRoles] = useState<{ description: string; name: string }[]>(
    []
  );
  const [selectedUser, setSelectedUser] = useState({
    roles: new Set(),
  } as User);
  const [showModal, setShowModal] = useState({
    showModal: false,
    edit: false,
  });

  const fetchData = async () => {
    setStatus("loading");
    const availableRoles = await apiService.getCall("user/roles");
    const response = await apiService.getCall("user");
    setStatus("resolved");
    setUsers(response);
    setRoles(availableRoles);
  };

  const closeModal = () => {
    setSelectedUser({ roles: new Set() } as User);
    setErrorMessage("");
    setShowModal({ showModal: false, edit: false });
  };

  const deleteUser = async (username: string) => {
    setStatus("loading");
    const response = await apiService.deleteCall(`user/remove/${username}`);
    if (!response.error) {
      fetchData();
    } else {
      setStatus("resolved");
    }
  };

  const handlePermits = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    const newRoles = selectedUser.roles;
    if (!checked) {
      newRoles.delete(value);
    } else {
      newRoles.add(value);
    }
    setSelectedUser({
      ...selectedUser,
      roles: newRoles,
    });
  };

  const editUser = (user: User) => {
    setShowModal({ showModal: true, edit: true });
    setSelectedUser({ ...user, roles: new Set(user.roles) });
  };

  const saveData = async () => {
    setErrorMessage("");
    let response;
    const newPass = selectedUser.password ? btoa(selectedUser.password) : null;
    const user = {
      ...selectedUser,
      roles: [...selectedUser.roles],
      password: newPass,
    };
    if (selectedUser.roles.size === 0) {
      setErrorMessage("Debe seleccionarse al menos un rol");
      return;
    }
    if (!showModal.edit) {
      response = await apiService.putCall(user, "user/add");
    } else {
      response = await apiService.putCall(user, "user/update");
    }
    if (!response.error && !(response.message === "Data is not valid.")) {
      await fetchData();
      setSelectedUser({ roles: new Set() } as User);
      setShowModal({ showModal: false, edit: true });
      alertService.success("Usuario actualizado con éxito");
    } else {
      setErrorMessage(
        "Ha ocurrido un error, verifique los datos y vuelva a intentarlo nuevamente"
      );
      if (response.message === "Users limit reached") {
        setErrorMessage("Se ha alcanzado el límite de usuarios disponibles");
      }
    }
  };

  const addNewUser = async () => {
    setShowModal({
      showModal: true,
      edit: false,
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (status === "loading" || status === "idle") {
    return (
      <div className="p-4">
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <Modal
        closeModal={() => closeModal()}
        size="3xl"
        classes="md:w-1/2"
        isOpen={showModal.showModal}
        title={showModal.edit ? "Editar Usuario" : "Agregar Usuario"}
      >
        <div>
          {!showModal.edit ? (
            <div>
              <div className="my-4">
                <label className="block text-sm font-medium text-gray-700">
                  Nombre Usuario
                </label>
                {!showModal.edit && (
                  <input
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setSelectedUser({
                        ...selectedUser,
                        username: event.target.value,
                      })
                    }
                    className="input w-full"
                    type="text"
                    value={selectedUser.username}
                  />
                )}
              </div>
              {(!selectedUser.username ||
                (selectedUser.username &&
                  selectedUser.username.length < 8)) && (
                <div className="bg-blue-400 text-white p-2 rounded-md my-2">
                  El Usuario debe contener al menos 8 caracteres
                </div>
              )}
            </div>
          ) : (
            <div className="my-4">
              <label className="block text-sm font-medium text-gray-700">
                Nombre Usuario <strong>{selectedUser.username}</strong>
              </label>
            </div>
          )}
          <div className="my-4">
            <label className="block font-medium text-gray-700">
              Contraseña
            </label>
            <input
              value={selectedUser.password}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setSelectedUser({
                  ...selectedUser,
                  password: event.target.value,
                })
              }
              className="input w-full"
              type="password"
            />
          </div>
          {(!selectedUser.password ||
            (selectedUser.password && selectedUser.password.length < 8)) && (
            <div className="bg-blue-400 text-white p-2 rounded-md my-2 max-w-fit">
              La contraseña debe contener al menos 8 caracteres y un número
            </div>
          )}
          <div className="my-4">
            <h2 className="block font-medium text-gray-700">Permisos</h2>
            {roles.map((permit) => (
              <div key={permit.name} className="flex items-center">
                <input
                  onChange={handlePermits}
                  className="input mr-2"
                  type="checkbox"
                  checked={
                    selectedUser.roles && selectedUser.roles.has(permit.name)
                  }
                  value={permit.name}
                />
                <label
                  htmlFor={`permit-${permit.name}`}
                  className="block text-sm font-medium text-gray-700"
                >
                  {permit.description}
                </label>
              </div>
            ))}
          </div>
          {errorMessage && (
            <div className="my-4 p-2 bg-red-600 text-white rounded-lg">
              {errorMessage}
            </div>
          )}
          <div className="mt-4">
            <Button
              disabledProp={
                !!(selectedUser.password && selectedUser.password.length < 8)
              }
              type="secondary"
              text="guardar"
              onClick={() => saveData()}
            />
          </div>
        </div>
      </Modal>
      <PageTitle title="Listado de usuarios" />
      <div className="">
        <div className="flex justify-between items-center">
          <p className="text-gray-600 text-sm">
            Administrar los usuarios y cuales son los roles de cada uno.
          </p>
          <Button
            type="secondary"
            onClick={() => addNewUser()}
            text="agregar usuario"
          />
        </div>
        <div className="my-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <table className="border-collapse table-auto w-full whitespace-no-wrap relative divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  Nombre de usuario
                </th>
                <th className="whitespace-nowrap px-2 py-3.5 text-right text-sm font-semibold text-gray-900">
                  <span className="sr-only">Acciones</span>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 text-gray-700">
              {users.map((user: User) => (
                <tr className="" key={user.username}>
                  <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-600 sm:pl-6">
                    {user.username}
                  </td>
                  <td className="relative flex space-x-2 items-center justify-end whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                    <PencilAltIcon
                      className="h-6 cursor-pointer hover:opacity-70"
                      onClick={() => editUser(user)}
                    />
                    {user.deleteAllowed && (
                      <TrashIcon
                        className="h-6 cursor-pointer hover:opacity-70"
                        onClick={() => deleteUser(user.username)}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Users;
